/* DisplayContent.css */
.display-content-content p,
.display-content-content h1,
.display-content-content h2,
.display-content-content h3,
.display-content-content h4,
.display-content-content h5,
.display-content-content h6,
.display-content-content span,
.display-content-content li,
.display-content-content a,
.display-content-content div {
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    text-align: left;
    margin-top: 0;
}

.display-content-content img {
    max-width: 80%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer; 
}

/* Tablet Styles (320px - 640px) */
@media (max-width: 640px) and (min-width: 320px) {
    .display-content-content p,
    .display-content-content h1,
    .display-content-content h2,
    .display-content-content h3,
    .display-content-content h4,
    .display-content-content h5,
    .display-content-content h6,
    .display-content-content span,
    .display-content-content li,
    .display-content-content a,
    .display-content-content div {
        font-size: 16px;
    }

    .display-content-content img {
        max-width: 80%;
    }
}

/* Mobile Styles (0px - 320px) */
@media (max-width: 320px) {
    .display-content-content p,
    .display-content-content h1,
    .display-content-content h2,
    .display-content-content h3,
    .display-content-content h4,
    .display-content-content h5,
    .display-content-content h6,
    .display-content-content span,
    .display-content-content li,
    .display-content-content a,
    .display-content-content div {
        font-size: 9px;
        max-width: fit-content;
    }

    .display-content-content img {
        max-width: 70%;
    }
}