.page-style {
  display: grid;
  grid-template-columns: 0.3fr 1.5fr 1.5fr;
  gap: 10px;
  background-color: white;
  min-height: 80vh;
  padding-bottom: 40px;
}

.header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: nowrap;
}

.info-style {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
}

.pass-fail-style {
  font-weight: bold;
}

.pass {
  color: green;
}

.fail {
  color: red;
}

.question-numbers-container-style {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.question-number-style {
  margin: 0 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  width: 90px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.selected-question-style {
  background-color: #f5fffa;
  color: white;
}
