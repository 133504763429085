/* ReviewAIMarking.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap'); /* Replace with the font you want to use */

.review-marking-header {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    line-height: 1.02;
    margin-bottom: 20px;
    font-size: 4rem; /* Further increased font size */
    font-weight: bold; /* Make it bold */
    color: #5F9EA0; /* Primary color */
}

.review-marking-table {
    margin-top: 20px;
}
