.assessment-paper-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    background-color: #c5d6dc;

}

.assessment-paper-document {
    box-sizing: border-box;
    background-color: #ffffff;
    margin-top: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    overflow-y: auto;
    width: 1000px;
}

.assessment-paper-page {
    padding: 20px;
    background-color: #ffffff;
    margin: 10px auto;
    height: 75vh;
}

/* Additional media query to ensure proper shrinking on small screens */
@media screen and (max-width: 700px) {
    .assessment-paper-document {
        width: 100%;
        margin: 0;
        padding: 10px;
    }
}
