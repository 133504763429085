/* ScriptDetails.css */

.marking-container {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  overflow-y: auto;
}

.marking-project-title {
  margin: 0;
  color: #3a464f;
  text-align: left;
  font-size: clamp(1rem, 2vw + 1rem, 1.5rem);
  font-weight: bold;
}

.instructions-text {
  margin: 0;
  color: #323e48;
  text-align: left;
  font-size: 0.875rem;
}

.instructions-link {
  color: #5F9EA0;
  text-decoration: underline;
  margin-left: 4px;
  font-size: 1rem;
}

.section-title {
  margin: 0;
  font-weight: bold;
  color: #323e48;
}

.text-field-multiline {
  padding: 12px 15px;
  color: #798187;
}

.button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.selected-file-text {
  margin-top: 8px;
  margin-left: 16px;
  color: #323e48;
}


@media screen and (max-width: 750px) {
    .card-container {
        padding: 0;
    }
}
