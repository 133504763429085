.landing-page-header {
  display: flex;
  background:  #fffbef;
  font-family: 'Poppins', sans-serif;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100px;
  padding: 15px 10px 20px 70px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.landing-page-header-logo {
  width: 180px;
  height: auto;
}

.landing-page-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
}

.landing-page-header-logo-nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.landing-page-header-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 30px;
}

.landing-page-header-nav ul li {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.landing-page-header-nav ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
}

/* The arrow icon */
.landing-page-header-arrow {
  display: inline-block;
  margin-left: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  padding: 4px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
  top: 0;
}

.landing-page-header-arrow.open {
  transform: rotate(-135deg);
}

.landing-page-header-nav-item:hover .landing-page-header-arrow {
  transform: rotate(225deg);
}


.landing-page-header-auth-buttons {
  align-items: center;
  padding: 50px 40px 50px 100px;
}

.landing-page-header-login-button {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  margin-right: 20px;
}

.landing-page-header-try-button {
  background-color: #15b8d0;
  color: white;
  padding: 17px 23px;
  border-radius: 400px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.landing-page-header-try-button:hover {
  background-color: #15b8d0;
    text-decoration: none; /* Remove underline from the link */
}

.landing-page-header-dropdown-card {
  position: absolute;
  top: 70px;
  left: 15%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  width: 400px;
  flex-direction: row;
}

.landing-page-header-dropdown-card-ai {
  position: absolute;
  top: 70px;
  left: 20%;
  right: 0;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  width: 400px;
}

.landing-page-header-dropdown-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.landing-page-header-dropdown-column {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 90%;
}

.landing-page-header-product-list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
}

.landing-page-header-dropdown-column h3 {
  font-size: 14px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.landing-page-header-dropdown-column ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.landing-page-header-dropdown-column ul li {
  margin-bottom: 15px;
  width: 100%;
  padding: 5px 0;
}

.landing-page-header-dropdown-column ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  display: block;
}

.landing-page-header-description {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
  white-space: wrap;
}

/* Updated styles for the menu toggle button */
.landing-page-header-menu-toggle {
  justify-content: center;
  display: none;
  align-items: center;
  background: #edecff;
  border-radius: 50%;
  border: none;
  padding: 10px;
  font-size: 20px;
  color: #15b8d0;
  cursor: pointer;
  position: absolute;
  right: 0px;
}

.landing-page-header-mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10000;
  transition: left 0.3s ease;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;
}

.landing-page-header-mobile-nav.open {
  left: 0;
  display: flex;
  flex-direction: column;
}

.landing-page-header-mobile-nav ul {
  width: 100%; /* Make sure the nav takes up full width */
  list-style: none;
  padding: 0;
  margin: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Mobile layout changes for flex display */
.landing-page-header-mobile-nav ul li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
}

/* Ensure the link and arrow are positioned properly */
.landing-page-header-mobile-nav ul li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: black;
  padding-right: 20px;
}

/* Position the arrow */
.landing-page-mobile-header-arrow {
  display: inline-block;
  margin-left: auto;
  border: solid black;
  border-width: 0 2px 2px 0;
  padding: 4px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.landing-page-mobile-header-arrow.open {
  transform: rotate(-135deg);
}

.landing-page-header-mobile-dropdown-list {
  display: none;
  font-size: 14px;
  width: 100%;
  border-radius: 5px;
}

.landing-page-header-mobile-dropdown-list li {
  margin: 0;
}

.landing-page-header-mobile-dropdown-list.open {
  display: block;
}

/* The link inside each list item */
.landing-page-header-mobile-dropdown-list a {
  padding: 0;
  font-size: 14px;
  display: block;
}

/* Close button for mobile menu */
.landing-page-header-close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #15b8d0;
  border-radius: 50%;
  border: none;
  font-size: 30px;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 898px) {

  .landing-page-header-logo {
    width: 100px;
    height: auto;
  }

  .landing-page-header {
    padding: 15px 10px 10px 20px;
  }

  .landing-page-header-menu-toggle {
    display: flex;
  }

  .landing-page-header-auth-buttons {
    display: none;
  }

  .landing-page-header-mobile-nav.open {
    display: flex;
    flex-direction: column;
  }

  .landing-page-header-nav {
    display: none;
  }

  .landing-page-header-nav.active {
    display: flex;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    background-color: #15b8d0;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
}
