/* src/features/NotFoundPage.css */
.not-found-container {
  text-align: center;
  margin-top: 100px;
  font-family: Arial, sans-serif;
}

.not-found-container h1 {
  font-size: 6rem;
  color: #5F9EA0; /* Your primary color */
}

.not-found-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.not-found-container p {
  font-size: 1.2rem;
  color: #333;
}

.go-home-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #5F9EA0;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.go-home-link:hover {
  background-color: #468B8B; /* A darker shade for hover effect */
}
