/* TestHeading.css */
.test-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.logo-image {
    float: left;
    width: 150px;
    margin: 20px;
}

.test-details {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    padding: 20px;
}

.assessmentHeading {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
}

.subjectHeading {
    margin: 0;
    font-size: 16px;
    text-align: right;
    font-weight: normal;
}

.gradeHeading {
    margin: 0;
    font-size: 16px;
    text-align: right;
    font-weight: normal;
}

@media (max-width: 640px) {
    .logo-image {
        width: 100px;
        /* Smaller size for smaller screens */
    }

    .subjectHeading, .gradeHeading {
        margin: 0;
        font-size: 13px;
        text-align: right;
    }

    .assessmentHeading {
        margin: 0;
        font-size: 15px;
    }
}