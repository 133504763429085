@media print {
    .question {
        page-break-inside: avoid;
        break-inside: avoid;
    }
}

.question-headline {
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.question-marks {
    text-align: right;
    width: 100%;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.instructions {
    margin-left: 0;
    margin-bottom: 10px;
    margin-top: 0;
}

.total-marks {
    text-align: right;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    font-family: 'Times New Roman', Times, serif;
}

.tag-display {
    display: flex;
    justify-content: flex-start;
    color: #5F9EA0;
     margin-top: 15px;
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    font-size: 32px;
}

.sub-question {
    flex: 1;
    align-items: baseline;
}

/* Tablet Styles (320px - 640px) */
@media (max-width: 640px) and (min-width: 320px) {
    .question-headline {
        font-size: 16px;
    }

    .question-marks {
        font-size: 16px;
    }

    .total-marks {
        font-size: 16px;
    }
}

/* Mobile Styles (0px - 320px) */
@media (max-width: 320px) {
    .question-headline {
        font-size: 11px;
    }

    .question-marks {
        font-size: 11px;
    }

    .total-marks {
        font-size: 11px;
    }
}