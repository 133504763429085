.tutoring-cover-page {
  width: 100%;
  max-width: 210mm;
  height: auto;
  padding: 0 10mm;
  box-sizing: border-box;
  margin: 10mm auto;
  border: 1px solid #000;
  display: grid;
  font-family: 'Poppins', sans-serif;
}

.tutoring-cover-page-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr; /* Divide into two columns */
}

.tutoring-cover-page-details {
  text-align: left;
  padding-right: 5mm; /* Add padding to the right to create space between details and image */
}

.tutoring-cover-page-logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tutoring-cover-page-logo {
  max-height: 60mm;
  width: auto;
}

.tutoring-cover-page-detail-item {
  display: flex;
  align-items: center;
  font-size: clamp(12px, 2vw, 18px); /* Responsive font size using clamp */
  margin: 5mm 0;
}

strong {
  margin-right: 2mm;
  font-size: clamp(12px, 2vw, 18px); /* Responsive font size using clamp */
}

.write-in {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin-left: 5mm;
}

/* Media query for screens smaller than or equal to 700px */
@media (max-width: 700px) {
  .tutoring-cover-page {
    padding: 0 5mm;  /* Reduce padding */
    margin: 0;  /* Remove margin for smaller screens */
  }

  .tutoring-cover-page-logo {
    max-height: clamp(30mm, 5vw, 60mm); /* Responsive size */
    width: auto;
  }

  .tutoring-cover-page-content {
    grid-template-columns: 1fr; /* Switch to single column */
  }

  .tutoring-cover-page-details {
    padding-right: 0; /* Remove padding on the right */
  }

  .tutoring-cover-page-logo-container {
    justify-content: center; /* Center the logo */
  }
}