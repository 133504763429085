/* Card container styling */
.ai-card-container {
  border: 1px solid #a1cccc;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #fff;
  max-width: 400px;
  height: 200px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.ai-card-container:hover {
  background-color: #f0f4f8;
  border-color: #5f9ea0;
}

/* Content (title and description) aligned to the right of the image */
.ai-card-content {
  display: flex;
  flex-direction: column; /* Stack title and description vertically */
  justify-content: flex-start;
  margin-left: 16px; /* Adds space between the image and the text */
}

.ai-card-title {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.ai-card-description {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
}

/* Icon positioned on the left */
.ai-card-icon {
  flex-shrink: 0; /* Prevents the image from shrinking when the card resizes */
}

.ai-card-icon-image {
  width: 50px;
  height: 50px;
}
