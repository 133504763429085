.sub-question {
    cursor: text;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 5px;
    margin-top: 0;
}

.sub-question-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.sub-question-content {
    display: flex;
    align-items: flex-start;
    width: 100%;
}


.sub-question-number {
    margin-right: 5px;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.sub-question-marks {
    margin-left: auto;
    font-size: 16px;
}

.answer-label {
    font-weight: bold;
    margin-bottom: 8px;
}

@media (max-width: 640px) and (min-width: 321px) {
    .sub-question-marks,
    .sub-question-number {
        margin-right: 5px;
        font-size: 16px;
        font-family: 'Times New Roman', Times, serif;
    }
}

@media (max-width: 320px) {
    .sub-question-marks,
    .sub-question-number {
        margin-right: 5px;
        font-size: 11px;
        font-family: 'Times New Roman', Times, serif;
    }
}