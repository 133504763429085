.footer {
    display: flex;
    justify-content: space-between;
    background-color: #d4e8e9; /* Lighter variant of #5f9ea0 */
    padding: 40px 100px;
    width: 100vw; /* Full-screen width */
    box-sizing: border-box; /* Ensure padding is inside the width */
    flex-wrap: wrap; /* Ensure content wraps */
}

.footer-section {
    width: 16%; /* Default width for larger screens */
    margin-bottom: 30px;
}

.footer-logo {
    width: 20%;
}

.footer-logo h2 {
    font-size: 1.8em;
    color: #466374; /* Darker variant of #5f9ea0 */
    margin-bottom: 10px;
}

.footer-logo p {
    font-size: 1.2em;
    color: #466374;
    margin-bottom: 20px;
}

.footer-button {
    background-color: #15b8d0;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.footer-button:hover {
    background-color: #3b6f72; /* Darker hover effect */
}

.footer-section h3 {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #333;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    color: #333;
}

.footer-section ul li {
    margin-bottom: 10px;
    font-size: 1em;
}

.footer-social {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.social-icons a {
    margin-right: 10px;
    font-size: 1.5em;
    color: #5f9ea0;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #3b6f72; /* Darker variant of hover effect */
}

.footer {
    border-top: 1px solid #ddd;
    margin-top: 40px;
}

/* Media query for screens smaller than 900px */
@media (max-width: 900px) {
    .footer {
        padding: 20px 40px;
    }

    .footer-section {
        width: 45%; /* Two sections side by side */
        margin-bottom: 20px;
    }

    .footer-logo {
        text-align: center;
        width: 100%; /* Full width for the logo section */
    }

    .footer-button {
        display: block;
        margin: 0 auto;
    }
}

/* Media query for screens smaller than 600px (mobile) */
@media (max-width: 600px) {
    .footer-section {
        width: 100%; /* Full width for each section on mobile */
    }

    .footer {
        padding: 20px;
    }

    .footer-logo {
        text-align: center;
    }
}
