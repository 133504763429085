@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,body {
    overflow-x: hidden;
}

* {
    font-family: Hind, Helvetica, "sans-serif";
}

.App {
    display: flex;
    overflow: hidden;
    height: 100vh;
    transition: margin-left 0.5s ease;
}

/* Media query for smaller laptop screens */
@media screen and (max-width: 1366px) {
    .App {
        height: 95vh;
    }
}

.Content-container {
    flex-grow: 1;
    display: flex;
    overflow-x: auto;
    overflow-y: auto;
}

.Content {
    margin-top: 50px;
    flex-grow: 1;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), transparent);
    border-image-slice: 1;
    overflow-x: auto;
    overflow-y: auto;
    max-width: 100vw;
    width: 100%;
    background-color: #f5f8fa;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.App-logo {
    height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 760px) {
    .Message {
        display: block;
        background-color: #5F9EA0;
        color: #212121;
        padding: 10px;
        text-align: center;
        font-size: 18px;
    }

    .MessageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}