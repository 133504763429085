/* AssessmentTable.css */
.assessment-table-container {
    height: 400px;
    width: 100%;
    margin: 20px;
}

.search-bar {
    margin: 50px;
}

.search-input {
    padding: 5px;
    font-size: 16px;
}

.centered-header {
    text-align: center;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin: 0 5px;
    color: #333;
    outline: none;
}

.email-image {
    width: 200px;
    height: 200px;
}

.email-image-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.share-icon-button {
    margin-left: 10px
}

.upload-tests {
    margin-left: 0px;
}

.recently-worked-heading,
.section-heading {
  font-size: 1.5rem; /* Increase the font size */
  text-align: left;
  margin-left: 10px;
  color: #466374;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .MuiDataGrid-columnHeader--alignLeft[data-field="subject"],
  .MuiDataGrid-columnHeader--alignLeft[data-field="grade"],
  .MuiDataGrid-cell--alignLeft[data-field="subject"],
  .MuiDataGrid-cell--alignLeft[data-field="grade"] {
    display: none;
  }
}
