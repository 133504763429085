.nested-question {
    background-color: whitesmoke;
}

.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editor-container {
    display: flex;
    align-items: center;
}

.editor {
    width: 100%; 
    margin-left: 10px;  
    margin-right: 10px; 
}

.memo {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;

    display: inline;
    position: relative;
}

.space {
    height: 20px;
}