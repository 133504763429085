.help-container {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

.help-options-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.help-option-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
    flex: 1;
}

.help-option-icon {
    color: #00b2e3;
    margin-bottom: 10px;
}

.help-option-text {
    color: #4a606e;
    margin-top: 5px;
}

.help-center-box {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.help-center-title {
    color: #4a606e;
    margin-bottom: 20px;
}

.faq-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.faq-link {
    color: #00b2e3;
    font-size: 1rem;
}

.faq-time {
    color: #a0a0a0;
    font-size: 0.8rem;
}

.see-all-link {
    margin-top: 20px;
    text-align: right;
}

.video-section-box {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 20px;
}

.video-section-title {
    margin-bottom: 20px;
    color: #4a606e;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-height: 320px;
    overflow-y: auto;
}

.video-container {
    text-align: center;
    border-radius: 10px; /* Round corners */
    overflow: hidden; /* To ensure the iframe respects the border-radius */
}

.video-container iframe {
    border-radius: 10px; /* Ensure the iframe content has rounded corners */
}


.video-title {
    margin-top: 10px;
    text-align: center;
    color: #4a606e;
    font-size: 0.9rem;
}

.see-all-videos-link {
    margin-top: 20px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #4a606e;
}
