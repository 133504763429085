.instructions-list {
  list-style-type: disc;
  margin-bottom: 20px;
  color: #494b4e;
}

.alert-box {
  background-color: #e0f7fa;
  padding: 15px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #b2ebf2;
  width: 100%;
}

.alert-title {
  color: #007bb5;
  margin-bottom: 10px;
}

.info-card {
  background-color: #e0f7fa;
  padding: 15px;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #b2ebf2;
  width: 100%;
}

.start-project-button {
  background-color: #00bcd4;
  color: white;
  margin-bottom: 20px;
}

.start-project-button:hover {
  background-color: #008c9e;
}

.slider-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
