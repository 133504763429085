.assessmentContainer {
    max-width: 100%;
    min-height: 800px;
    background-color: #F5FFFA;
    padding: 20px;
}

.questionContainer {
    background-color: #fff;
    min-height: 600px;
    border-radius: 15px 25px 25px 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    overflow: auto;
}

.uploadSolutionButton {
    padding: 5px;
    margin-bottom: 20px;
}

.navigationButtons {
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.moveButton {
    padding: 10px 20px;
    font-size: 16px;
    margin: 0 5px;
}

.nextQuestionButton, .previousQuestionButton {
    margin: 5px;
}

.submit-button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 0 5px;
    color: #fff;
    background-color: #5F9EA0;
}

.uploadedImageContainer {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}

.deleteImageButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.deleteImageButton:hover {
    background-color: darkred;
}

.test-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.menu-icon {
    cursor: pointer;
    margin-left: 10px;
    font-size: 24px;
    /* Adjust size as needed */
}

.test-details {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    padding: 20px;
}

.assessmentHeading {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
}

.assessment-container-loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: cadetblue;
}

.assessment-container-heading {
    color: white;
    font-size: 2em;
    text-align: center;
}

.assessment-container-loading {
    color: white;
    margin-left: 20px;
}

.subjectHeading {
    margin: 0;
    font-size: 16px;
    text-align: right;
    font-weight: normal;
}

.gradeHeading {
    margin: 0;
    font-size: 16px;
    text-align: right;
    font-weight: normal;
}

.time-left {
    text-align: right;
    font-style: italic;
    /* Italicize the timer text for emphasis */
}

.timeLeftHeading {
    margin: 0;
    font-size: 18px;
}

.logo-image {
    float: left;
    width: 300px;
    margin: 20px;
}

@media (max-width: 640px) {
    .submitButton {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
    }

    .logo-image {
        width: 300px;
        /* Smaller size for smaller screens */
    }

    .subjectHeading, .gradeHeading {
        margin: 0;
        font-size: 13px;
        text-align: right;
    }

    .assessmentHeading {
        margin: 0;
        font-size: 15px;
    }

    .navigationButtons {
        flex-direction: row;
    }

    .assessmentContainer {
        max-width: 100%;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
    }

    .questionContainer {
        padding: 10px;
        background-color: #fff;
        border-radius: 15px 25px 25px 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin: 10px 0; /* Ensure margin for small screens */
    }

    .time-left {
        text-align: right;
        font-style: italic;
    }

    .timeLeftHeading {
        margin: 0;
        font-size: 10px;
    }
}