.blog-container {
  padding: 0;
  margin: 0;
}

.blog-content-wrapper {
  padding: 20px 40px; /* Adjust the padding as needed */
  max-width: 900px;
  margin: 0 auto;
}

.blog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header-icon {
  font-size: 40px;
  margin-right: 10px;
}

.header-text {
  flex-grow: 1;
}

.blog-subtitle {
  color: #888;
  font-size: 1.2em;
  margin-top: 10px;
}

.author-info {
  display: flex;
  align-items: center;
}

.author-avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.author-details {
  color: #333;
}

.author-name {
  font-weight: bold;
}

.publish-date {
  font-size: 0.9em;
  color: #888;
}
