.grading-column {
  overflow: auto;
  max-height: 900px;
  width: 100%;
  background-color: #f4f4f4; /* Set a light background color */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left; /* Align text to the left */
}

.no-feedback {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.answer-item {
  width: 100%; /* Ensure each item takes full width */
  position: relative;
  margin-bottom: 20px; /* Add space between items */
  background-color: #f9f9f9;
}

.button-group {
  display: flex;
  gap: 10px; /* Spacing between the buttons */
}

.button-group button {
  margin-top: -10px; /* Adjust margin to align with Answer ID */
}
