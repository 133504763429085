* {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}

.nav-bar-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-bar {
    height: 100%;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: all 0.5s ease;
    border-right: 1px solid #b3cacc;
    width: 243px;

}

.nav-bar.show {
    width: 260px;
    min-width: 220px;

}

.nav-logo {
    padding: 5px;
    border-radius: 5px;
    width: 150px;
    height: auto;
    margin-top: 10px;
}

.menu-list {
    flex-grow: 1; /* Makes the menu list grow to take up space */
    display: flex;
    flex-direction: column;
}

.menu-item {
    transition: all 0.3s ease;
}

.menu-item.active-item {
  background-color: #e0f7fa; /* A light shade for the selected item */
}

.menu-item:hover {
  background-color: #c2e2e7; /* Optional hover effect */
}

.nav-bar-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    left: 10px;
}

.nav-bar-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.nav-bar-profile-details {
    position: absolute;
    bottom: 60px; /* Ensure dropdown is above the profile */
    left: 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    white-space: nowrap;
    margin-bottom: 10px;
}

.close-navbar-home-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}



/* Hiding the logo on small screens */
@media (max-width: 768px) {
    .nav-logo {
        display: none;
    }

    .nav-bar {
        background: linear-gradient(to right, #ffffff 60%, rgba(0, 0, 0, 0.5) 50%);
        position: fixed;
        z-index: 1001;
        width: 100%;
    }

    .menu-list {
    width: 60%;
}

    .nav-bar.show {
        width: 250px;
    }

    .main-content.shifted {
        margin-left: 250px;
        transition: margin-left 0.3s ease;
    }

    .close-nav-button {
        display: inline-block;
        border: none;
        cursor: pointer;
        color: white;
        font-size: 18px;
    }
}
