.background {
    background-color: #f5fffa;
    padding: 10px;
}

.educase-tools-ai-heading {
    color: #5F9EA0;
    font-size: 25px;
}

.button-icon {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: white;
}

.container {
    display: flex;
    flex-direction: column;
    height: 60vh;
    background-color: #f5fffa;
    justify-content: flex-end;
}

.educase-tools-ai-heading {
    text-align: center;
}

.input-form {
    display: flex;
    justify-content: space-between; 
    padding: 20px;
}

/* Styles for the input field */
.input-form input[type="text"] {
    flex-grow: 1;
    margin-right: 10px; 
}
.button-icon {
    /* Style your button */
}

.ai-logo {
  width: 50px; /* or any other size */
  height: auto;
  /* Add more styles if needed */
}

.suggestion {
    border: 1px black solid;
}