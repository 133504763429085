.answer-image-containter {
    margin: 10px;
}

.answer-image {
    max-width: 100px;
    max-height: 100px;
}

.uploadArea {
    padding: 20px;
    border: 3px dashed #5F9EA0;
    cursor: pointer;
}

.answer-image-container {
    position: relative;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.uploadArea,
.filesContainer {
    opacity: 1;
}

.uploadArea.loading,
.filesContainer.loading {
    opacity: 0.5; /* Adjust the opacity as needed */
}
