/* Base Styles */
.profile-edit-container {
    max-width: 1000px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #79aeaf;
    background-color: #fff;
    position: relative;
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-avatar {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 50%;
    object-fit: cover; /* Ensures the image fits nicely within the avatar */
}

.profile-edit-title {
    color: #466374;
    font-weight: 600;
    margin-bottom: 5px;
}

.profile-edit-subtitle {
    color: #466374;
    margin-bottom: 10px;
}

.profile-divider {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin-bottom: 30px;
}

.profile-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.profile-label {
    font-weight: 500;
    color: #333;
    flex: 0 0 120px;
}

.profile-value {
    color: #555;
    margin-right: 20px; /* Adjusted to make room for the link */
    padding-left: 20px;
    flex: 1;
}

.profile-change-link {
    color: #79aeaf;
    text-decoration: none;
    font-weight: 500;
    margin-right: 20px;
}

.profile-contact-link {
    color: #79aeaf;
    text-decoration: none;
    font-weight: 500; /* Same font weight as the 'Change' button */
    margin-right: 20px; /* Aligns with the 'Change' button */
}

.profile-contact-link:hover,
.profile-change-link:hover {
    text-decoration: underline;
}

.MuiFormControl-root {
    margin: 0; /* Remove default margin */
}

/* Adjusted button position */
.profile-save-button {
    position: absolute;
    top: -40px; /* Keeps the button close to the top */
    right: 20px; /* Keeps the button aligned to the right edge */
    background-color: #79aeaf;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    font-weight: 600;
    z-index: 10; /* Ensures the button is above other elements */
}

/* Adjusted picture container */
.profile-pic-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the contents horizontally */
    position: relative;
}

.profile-avatar {
    width: 150px;  /* You can adjust the size */
    height: 150px; /* You can adjust the size */
    border-radius: 50%; /* Makes the image circular */
    border: 5px solid #5F9EA0; /* Light blue border color */
    object-fit: cover; /* Ensures the image covers the circle without distortion */
}


.edit-pic-button {
    background-color: transparent; /* Make background transparent */
    color: #79aeaf;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: none; /* Remove the shadow */
    font-size: 12px;
    border: none; /* Remove border */
    text-align: center; /* Center the text */
}

.edit-pic-button:hover {
    background-color: #e0f0f0; /* Add a slight hover effect */
}

/* Responsive Styles */

/* Small screens (phones, < 600px) */
@media (max-width: 599px) {
    .profile-edit-container {
        max-width: 100%;
        padding: 15px;
    }

    .profile-header {
        flex-direction: column;
        align-items: flex-start;
    }

.profile-avatar {
    width: 150px;  /* Set the width of the image */
    height: 150px; /* Set the height of the image */
    border-radius: 50%; /* Makes the image circular */
    border: 5px solid #5F9EA0; /* Light blue border */
    object-fit: cover; /* Ensures the image covers the entire circle */
    display: block; /* Makes sure the image is displayed as a block element */
    background-color: white; /* Optional: Add background color for transparent images */
}

    .profile-label {
        flex: 0 0 100px;
    }

    .profile-value {
        padding-left: 10px;
    }

    .profile-save-button {
        position: static;
        width: 100%;
        margin-top: 20px;
    }
}

/* Medium screens (tablets, 600px - 1023px) */
@media (min-width: 600px) and (max-width: 1023px) {
    .profile-edit-container {
        max-width: 90%;
        padding: 20px;
    }

    .profile-avatar {
        width: 90px;
        height: 90px;
        margin-right: 15px;
    }

    .profile-save-button {
        top: -30px;
        margin-right: 100px;
    }
}

/* Large screens (desktops, 1024px - 1439px) */
@media (min-width: 1024px) and (max-width: 1439px) {
    .profile-edit-container {
        max-width: 80%;
    }

    .profile-avatar {
        width: 110px;
        height: 110px;
        margin-right: 25px;
    }

    .profile-save-button {
        top: 20px;
        margin-right: 100px;
    }
}

/* Extra-large screens (large desktops, 1440px and up) */
@media (min-width: 1440px) {
    .profile-edit-container {
        max-width: 1000px;
    }

    .profile-avatar {
        width: 120px;
        height: 120px;
        margin-right: 30px;
    }

     .profile-save-button {
        top: 20px;
    }
}
