/* Tab Navigation Styling */
.tab-navigation {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  margin-left: 20px;
}

.tab {
  padding-top: 10px;
  background-color: transparent; /* No background color */
  cursor: pointer;
  border-radius: 0; /* No rounded corners */
  font-size: 14px;
  color: #333; /* Text color */
  outline: none; /* Remove focus outline */
  border: none; /* No border */
}

.active-tab {
  padding-top: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #5f9ea0;
  font-size: 14px;
}

/* Tab Content Styling */
.tab-content {
  padding: 20px;
}
