.centered-header {
    text-align: center;
}

.MuiDataGrid-root {
    border: none;
}

.MuiDataGrid-cell {
    border-bottom: 1px solid #e0e0e0;
}

.MuiDataGrid-columnHeaders {
    border-bottom: 1px solid #e0e0e0;
}
