/* src/components/SignUpPage/TestSignUpPage.css */
.test-sign-up-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: floralwhite;
  padding: 20px;
  box-sizing: border-box;
}

.test-sign-up-page-logo {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-width: 25%;
}

.test-sign-up-page-card-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.test-sign-up-page-card {
  text-align: center;
  border-radius: 15px;
  background: #ffffff;
  max-width: 640px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.test-sign-up-page-title {
  font-size: 2.5em;
  color: #5F9EA0;
  font-family: 'Arial, Helvetica, sans-serif';
  margin-bottom: 20px;
}

.test-sign-up-page-subtitle {
  font-size: 1.2em;
  color: #333;
  font-family: 'Arial, Helvetica, sans-serif';
  margin-bottom: 40px;
}

/* Responsive styles for mobile: 0-320px */
@media (max-width: 320px) {
  .test-sign-up-page-card {
    padding: 20px;
  }

  .test-sign-up-page-logo {
    max-width: 80%;
  }

  .test-sign-up-page-title {
    font-size: 1.5em;
  }

  .test-sign-up-page-subtitle {
    font-size: 0.9em;
  }
}

/* Responsive styles for tablet: 321px-640px */
@media (min-width: 321px) and (max-width: 640px) {
  .test-sign-up-page-card {
    padding: 30px;
  }

  .test-sign-up-page-logo {
    max-width: 50%;
  }

  .test-sign-up-page-title {
    font-size: 2em;
  }

  .test-sign-up-page-subtitle {
    font-size: 1em;
  }
}

/* General responsive styles for larger tablets and small desktops: 641px-768px */
@media (min-width: 641px) and (max-width: 768px) {
  .test-sign-up-page-card {
    padding: 40px;
  }

  .test-sign-up-page-logo {
    max-width: 40%;
  }

  .test-sign-up-page-title {
    font-size: 2.2em;
  }

  .test-sign-up-page-subtitle {
    font-size: 1.1em;
  }
}

/* Styles for laptop screens and above */
@media (min-width: 769px) {
  .test-sign-up-page-card {
    padding: 20px;
    max-width: 640px;
  }
}