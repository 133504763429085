/* Sticky header for Image Upload title, Avatar, and Camera Icon */
.header-container {
  display: flex;
  flex-direction: row; /* Align the avatar, title, and icon horizontally */
  align-items: center; /* Vertically align the avatar, title, and icon */
  justify-content: space-between; /* Space between the text and the camera icon */
  width: 100%;
  padding: 10px 0; /* Add padding for spacing */
  background-color: white; /* Ensure the background is solid */
  border-bottom: 1px solid #ddd; /* Optional: add a border below the header */
}

/* Style the avatar with the primary color */
.header-avatar {
  background-color: #5F9EA0; /* Primary color */
  color: white; /* Ensure the text inside the avatar is visible */
  margin-right: 10px; /* Add space between avatar and the title */
}

/* Style the title with some space */
.header-title {
  margin: 0;
  font-size: 1.25rem; /* Adjust font size if necessary */
  flex-grow: 1; /* Let the title take up remaining space */
}

/* Style the camera icon with the primary color */
.camera-icon {
  color: #5F9EA0; /* Primary color */
}

.camera-icon {
  display: none;
}

@media (max-width: 767px) {
  .camera-icon {
    display: inline-flex; /* Show camera icon only for mobile screens */
  }
}


/* Divider line */
.divider {
  width: 100%;
  height: 1px;
  background-color: #ddd; /* Light gray for the divider line */
  margin: 10px 0; /* Space between the divider and surrounding elements */
}

/* Flexbox for image header: Image number and Delete icon */
.image-header {
  display: flex;
  justify-content: space-between; /* Image number on the left, delete icon on the right */
  align-items: center;
  padding: 5px 10px; /* Some padding around the text and icon */
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  width: 100%; /* Ensure it spans the entire width of the image */
  box-sizing: border-box;
  z-index: 10; /* Ensure it's above the image */
}

.learner-uploaded-files  {
  position: relative;
  margin-bottom: 20px; /* Space between files */
  display: flex;
  flex-direction: column; /* Stack image and header vertically */
  align-items: center;
  justify-content: center;
}

.uploaded-image {
  width: 100%;
  max-width: 600px; /* Limit the width of the image */
  height: auto;
  border: 1px solid #ddd; /* Optional border around the image */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
}

/* Updated iconButton style for the delete button */
.iconButton {
  padding: 0;
  color: black; /* Change color from red to black */
}

.upload-image {
    width: 100%;
}