.rubric-container {
    background-color: #ffffff;
    padding: 10px;
    scrollbar-width: thin;
    height: 80vh;
    overflow-y: auto;
}

.rubric-question-header {
    margin-bottom: 10px;
}

.rubric-question-headline {
    font-size: 20px; /* Make the question headline stand out more */
    margin-bottom: 10px;
    font-weight: 600;
    color: #333; /* Darker color for better readability */
}

.rubric-total-marks {
    font-size: 16px;
    font-weight: bold;
    color: #2d2d2d;
    margin-bottom: 15px;
}

.rubric-question-instructions {
    font-size: 14px;
    margin-bottom: 15px;
    color: #555;
}

.rubric-sub-question-container, .rubric-nested-question-container {
    padding-left: 20px;
    margin-bottom: 20px;
    align-items: baseline;
    padding-top: 10px;
}

.rubric-sub-question-content, .rubric-flex-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Add space between the question label and content */
    font-size: 16px;
}

.rubric-answer-container {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 14px;
}

.rubric-answer-label {
    font-weight: bold;
    color: #2d72d9; /* Highlight the answer label */
    display: block;
    margin-bottom: 5px;
}

.rubric-marks-input {
    margin-left: 10px;
    padding: 8px;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.rubric-marks-input:focus {
    border-color: #2d72d9; /* Blue border on focus */
    outline: none;
}

.rubric-label {
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

.rubric-close-button {
    margin-top: 5px;
    padding: 5px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.rubric-close-button:hover {
    background-color: #d32f2f;
}

.rubric-sub-question-container,
.rubric-nested-question-container {
    padding-left: 10px; /* To avoid content being too close to the border */
    margin-bottom: 10px;
    transition: border-color 0.3s ease;
}


.mark-chips {
    display: flex;
    gap: 15px; /* Larger space between chips */
    margin-bottom: 10px;
    flex-wrap: wrap; /* Ensure it wraps on smaller screens */
}

.chip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8em;
    max-width: 100px; /* Increased width */
    height: 30px; /* Increased height */
    background-color: #f0f0f0;
    border-radius: 50%; /* Circular shape */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px; /* Larger font */
    font-weight: bold;
    border: 2px solid #ccc; /* Adds a subtle border */
}

.chip:hover {
    background-color: #d0d0d0; /* Hover effect */
}

.chip.selected {
    background-color: #5F9EA0; /* Use your primary color */
    color: #fff;
    border-color: #5F9EA0; /* Match the selected chip's border */
}

.chip.skipped {
    background-color: #FFC107;
    color: black;
    border: 1px solid #FFC107;
    cursor: pointer;
}

.chip.skipped.selected {
    background-color: #FFA000;
    color: white;
}


