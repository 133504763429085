.thank-you-image {
    width: 30%;
    margin-bottom: 20px;
    border: 5px solid blueviolet;
}

@media (max-width: 600px) {
    .thank-you-image {
        width: 85%;
        margin-bottom: 15px;
    }
}

@media (min-width: 601px) and (max-width: 960px) {
    .thank-you-image {
        width: 65%;
        margin-bottom: 20px;
    }
}

@media (min-width: 961px) {
    .thank-you-image {
        width: 30%;
        margin-bottom: 25px;
    }
}