/* landingPage.css */

.landing-page-container {
  font-family: 'Poppins', sans-serif;
  background-color: #fffbef;
  display: flex;
  flex-direction: column;
  min-height: 200vh;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.section-spacing {
  margin-bottom: 40px;
}

/* Fill up space and push the footer down */
.landing-page-content {
  flex: 1;
  width: -webkit-fill-available;
}

/* Responsive design for tablets */
@media screen and (max-width: 750px) {
  .section-spacing {
    margin-bottom: 30px;
  }
}

/* Responsive design for mobiles */
@media screen and (max-width: 480px) {
  .section-spacing {
    margin-bottom: 20px;
  }
}
