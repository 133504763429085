.exam-test-heading {
  justify-content: space-between;
  align-items: center;
  background: #f0f0f0;
  border-radius: 15px;
}

/* Add any additional styles for the AssessmentTable component as needed */
/* Existing styles... */

.overview-screen-right-section {
  text-align: right;
}

.create-paper-button {
  margin-bottom: 20px;
  /* Add space below the button */
}

.assessment-table-wrapper {
  margin-top: 10px;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000;
}

.exam-test-container {
    position: relative;
    margin: 10px;

    @media only screen and (max-width: 700px){
        margin: 5px;
    }
}


