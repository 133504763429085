/* General dashboard layout */
.dashboard {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: #323e48;
  height: 100%;
  overflow-y: auto;
  flex-direction: row;
}

.main-content {
  flex-grow: 1;
  padding-bottom: 60px;
}

.upcoming-tests-section {
  flex-basis: 350px;
  margin-left: 20px;
}

.section-heading {
  font-size: clamp(0.5rem, 5vw, 1.5rem);
  color: #323e48;
  margin-bottom: 20px;
}

.upcoming-tests-list {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  height: 70%;
  border: 1px solid #5f9ea0;
}

.upcoming-test-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.subject-avatar {
  width: 40px;
  height: 40px;
  background-color: #5F9EA0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  margin-right: 10px;
}

.test-name {
  font-weight: bold;
  color: black;
}

.test-date {
  color: #466374;
}

.divider {
  height: 1px;
  background-color: #e6e0e0;
  margin: 10px 0;
}

/* Horizontal grid for larger screens */
.horizontal-grid {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.horizontal-grid::-webkit-scrollbar {
  height: 8px;
}

.horizontal-grid::-webkit-scrollbar-thumb {
  background-color: #5F9EA0; /* Customize the scrollbar color */
  border-radius: 4px;
}

/* Marked tests list layout using Flexbox */
.marked-tests-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 400px;
  margin-bottom: 5px;
  justify-content: flex-start; /* Align items to the start on larger screens */
}

/* Marked tests item styling */
.marked-test-item {
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #466374; /* Border color */
  border-radius: 8px;
  text-align: left;
  width: 350px; /* Fixed width for consistency */
}

.marked-test-item .test-name {
  font-weight: bold;
  color: #498292; /* Bright color for the test name */
  margin-bottom: 10px;
}

.test-score {
  margin-bottom: 5px;
}

/* Responsive layout for smaller screens */
@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column; /* Stack the layout on smaller screens */
  }

  .upcoming-tests-section {
    flex-basis: 100%; /* Full width on smaller screens */
    margin-left: 0;
    margin-bottom: 20px;
  }

  .main-content {
    flex-grow: unset;
    padding-bottom: 40px;
  }

  .horizontal-grid {
    flex-direction: column; /* Stack the test cards vertically */
    flex-wrap: wrap; /* Allow wrapping */
    gap: 10px; /* Reduce the gap between items on smaller screens */
    overflow-x: visible; /* Disable horizontal scroll */
  }

  .marked-tests-list {
    flex-direction: column; /* Stack items vertically */
    gap: 10px;
        align-items: stretch; /* Ensures each item takes full width */
  }

  .marked-test-item {
    width: 100%; /* Full width on smaller screens */
  }
}

.see-all-button-container {
  text-align: center; /* Centers the button horizontally */
  margin-top: 20px; /* Adds spacing above the button */
}

.see-all-button {
  color: #498292;
  font-weight: bold;
  padding: 10px 20px; /* Adds padding to the button */
}
