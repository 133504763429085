.tos-terms-of-service {
    padding: 20px;
}

.tos-terms-content {
    margin: 0 auto;
    max-width: 800px;
}

.tos-terms-header {
    margin-bottom: 20px;
}

.tos-terms-title {
    font-size: 2rem;
    margin-bottom: 10px;
}

.tos-terms-updated {
    font-size: 0.9rem;
    color: #777;
}

.tos-terms-p {
    margin-bottom: 15px;
}

.tos-terms-h2, .tos-terms-h3 {
    margin-bottom: 10px;
    font-weight: bold;
}

.tos-terms-h2 {
    font-size: 1.5rem;
}

.tos-terms-h3 {
    font-size: 1.2rem;
}

.tos-terms-ul {
    margin-left: 20px;
    margin-bottom: 15px;
}

.tos-terms-li {
    margin-bottom: 10px;
}

.tos-terms-a {
    color: #007bb5;
    text-decoration: underline;
}

.tos-section-spacing {
    margin-bottom: 50px;
}
