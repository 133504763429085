/* General Styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fffbef;
    color: #5F9EA0;
    font-family: Arial, sans-serif;
    padding: 16px;
}

.drag-and-drop-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.reset-password-button {
    background-color: #5F9EA0;
}

.inputField {
    width: 15rem;
    margin-bottom: 16px;
}

.MuiOutlinedInput-root {
    background-color: white;
}

.MuiOutlinedInput-root fieldset {
    border-color: black;
}

.MuiOutlinedInput-root:hover fieldset {
    border-color: black;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: black;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    height: 3rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    background: black;
    color: white;
    font-weight: bold;
}

.container > *:not(:last-child) {
    margin-bottom: 1rem;
}

.welcome {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 10px !important;
}

a {
    font-size: 17px;
}

.custom-box {
    width: 200px;
    height: 50px;
}

.left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #ffffff;
}

.css-mh4gi9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 !important;
}

/* Mobile (0-320px) */
@media (max-width: 320px) {
    .logo {
        font-size: 1.2rem;
        left: 0.5rem;
        width: 200px;
    }

    .inputField, .button {
        width: 100%; /* Full width on mobile */
    }

    .welcome {
        font-size: 1.5rem;
    }

    .custom-box {
        width: 100%;
        height: 40px;
    }

    .container {
        padding: 10px;
    }
}

/* Tablet (321-640px) */
@media (min-width: 321px) and (max-width: 640px) {
    .logo {
        font-size: 1.5rem;
        left: 1rem;
        width: 250px;
    }

    .inputField, .button {
        width: 80%; /* Smaller width on tablet */
    }

    .welcome {
        font-size: 1.75rem;
    }

    .custom-box {
        width: 80%;
        height: 45px;
    }

    .container {
        padding: 20px;
    }
}

/* Laptop (641px and up) */
@media (min-width: 641px) {
    .logo {
        font-size: 1.5rem;
        width: 300px;
    }

    .inputField, .button {
        width: 15rem; /* Fixed width for larger screens */
    }

    .welcome {
        font-size: 2rem;
    }

    .custom-box {
        width: 200px;
        height: 50px;
    }

    .container {
        padding: 40px;
    }
}