.test-welcome-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: floralwhite;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.logo {
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    max-width: 25%;
}

.children {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid white;
}

.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.image-container img {
    width: 100%;
    height: auto;
}

.centered-text {
    color: #5F9EA0;
    font-size: 1.5rem;
    text-align: center;
    padding: 0 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}

.get-started-test {
    background-color: #5F9EA0;
    color: white;
    margin-top: 1em;
    width: 100%;
    max-width: 200px;
}

/* Responsive styles */
@media (max-width: 320px) {
    .logo {
        max-width: 80%;
    }
}

@media (min-width: 600px) {
    .children {
        width: 200px;
        height: 200px;
    }
}
