/* testWidget.css */

.test-widget {
  width: 300px;
  border-radius: 0px;
  border: 1px solid #d1dee3;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.test-widget:hover {
  background-color: #f0f4f8;
  cursor: pointer;
}

.test-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
}

.test-details {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.test-avatar {
  width: 40px;
  height: 40px;
  color: white;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 50%;
}

.test-title {
  flex-grow: 1;
}

.test-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  color: #323e48;
}

.test-info {
  font-size: 12px;
  color: gray;
}

.test-preview {
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #f4f6f8;
  margin-bottom: 8px;
}

.more-options {
  position: absolute;
  display: block;
}

.test-widget:hover .more-options {
  display: block;  /* Show on hover */
}

.test-widget .MuiMenu-paper {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

/* Menu Header Styling */
.menu-header {
  padding: 10px 16px;
  background-color: #f9f9f9;
}

.menu-header-title {
  font-weight: bold;
  font-size: 16px;
  color: #323e48;
  margin-bottom: 4px;
}

.menu-header-info {
  font-size: 12px;
  color: gray;
}

.MuiDivider-root {
  margin: 0 !important;
}

.MuiMenuItem-root {
  border-radius: 8px;  /* Curved edges for each menu item */
}