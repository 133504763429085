.create-instructions-container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure the container takes up the full width */
  padding: 20px;
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  width: 100%; /* Ensure it stretches to the full width of the container */
}

.filled-button {
  background-color: #46827a; /* Button color */
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  text-transform: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filled-button:hover {
  background-color: #366e65; /* Darker hover effect */
}
