.uploadAnswersContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 0 auto;
    max-width: 900px;
}

.summary {
    margin-bottom: 20px;
}

.instructions-card {
    background-color: #fffae6;
    padding: 15px;
    border: 1px solid #f1c40f;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

/* Uploaded Images Section */
.uploaded-images-section {
    margin-bottom: 20px;
}

.uploaded-images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.uploaded-image-wrapper {
    position: relative;
}

.uploaded-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Loading Screen */
.upload-answers-container-loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #5F9EA0;
    color: white;
    text-align: center;
}

.upload-answers-container-heading {
    font-size: 1.8rem;
    margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .uploadAnswersContainer {
        padding: 10px;
        margin: 0 10px;
    }

    .instructions-card {
        font-size: 14px;
    }

    .uploaded-images-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .uploaded-image {
        max-width: 100%;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    .uploaded-images-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}
