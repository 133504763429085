/* src/components/SignInPage/TestSignInPage.css */
.sign-in-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffbef;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.sign-in-page-logo {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-width: 25%;
}

.sign-in-page-card-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.sign-in-page-card {
  text-align: center;
  border-radius: 15px;
  background: #ffffff;
  max-width: 640px;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sign-in-page-title {
  font-size: clamp(1.7rem, 4vw, 2.3rem);
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
    color: #323e48;
}

.sign-in-page-subtitle {
  font-size: 1.2em;
  color: #333;
  font-family: 'Arial, Helvetica, sans-serif';
  margin-bottom: 40px;
}

.sign-in-form {
  padding: 20px;
}

/* Responsive styles for mobile: 0-320px */
@media (max-width: 320px) {
  .sign-in-page-card {
    padding: 0px;
  }

  .sign-in-page-logo {
    max-width: 80%;
  }

  .sign-in-page-title {
    font-size: 1.5em;
  }

  .sign-in-page-subtitle {
    font-size: 0.9em;
  }
}

/* Responsive styles for tablet: 321px-640px */
@media (min-width: 321px) and (max-width: 640px) {
  .sign-in-page-card {
    padding: 0px;
  }

  .sign-in-page-logo {
    max-width: 50%;
  }

  .sign-in-page-title {
    font-size: 2em;
  }

  .sign-in-page-subtitle {
    font-size: 1em;
  }
}

/* General responsive styles for larger tablets and small desktops: 641px-768px */
@media (min-width: 641px) and (max-width: 768px) {
  .sign-in-page-card {
    padding: 0;
  }

  .sign-in-page-logo {
    max-width: 40%;
  }

  .sign-in-page-title {
    font-size: 2.2em;
  }

  .sign-in-page-subtitle {
    font-size: 1.1em;
  }
}

/* Styles for laptop screens and above */
@media (min-width: 769px) {
  .sign-in-page-card {
    padding: 0px;
    max-width: 640px;
  }
}