.student-results-page-container {
    padding: 10px;
    font-family: Arial, sans-serif;
    color: black;
    background-color: floralwhite;
}

.feedback-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-header h2 {
    margin-right: 15px;
}

.student-results-page-logo {
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    max-width: 25%;
}

.student-results-page-well-done {
    width: 25%;
    border: 1px solid white;
    border-radius: 50%;
}

.student-results-page-results-heading {
    font-size: 25px;
}

.student-results-page-card-wrapper {
    max-width: 850px;
    margin: 0 auto;
    padding: 10px;
}

.welcome-message {
    text-align: center;
    margin-bottom: 20px;
}

.student-results-page-question-container {
    background-color: white;
    max-width: 650px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 10px;
}

.student-results-page-question-mark {
    font-size: 25px;
}

.student-results-page-question-number-box {
    border: 1px solid #5F9EA0;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 15px;
}

.student-results-page-question-number {
    font-weight: bold;
}

.navigationButtons {
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.nextQuestionButton,
.previousQuestionButton {
    margin: 5px;
}

.student-results-page-question-content-box,
.student-results-page-answer-box,
.student-results-page-feedback-box {
    border: 1px solid #5F9EA0;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

@media (max-width: 320px) {
    .student-results-page-logo {
        max-width: 80%;
    }

    .navigationButtons {
        flex-direction: row;
    }
}

@media (min-width: 321px) and (max-width: 768px) {
    .student-results-page-logo {
        max-width: 80%;
    }

    .navigationButtons {
        flex-direction: row;
    }
}

@media (min-width: 769px) {
    .student-results-page-logo {
        padding: 20px;
        max-width: 640px;
    }

    .navigationButtons {
        flex-direction: row;
    }
}