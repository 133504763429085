/* src/components/SignUpPage/TestSignUpPage.css */
.sign-up-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffbef;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.sign-up-form {
  padding: 20px;
}

.sign-up-page-logo {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-width: 25%;
}

.sign-up-page-card-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.sign-up-page-card {
  text-align: center;
  border-radius: 15px;
  background: #ffffff;
  max-width: 640px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signup-title {
    font-size: clamp(1.7rem, 4vw, 2.3rem);
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
    color: #323e48;
}

.sign-up-page-subtitle {
  font-size: 1.2em;
  color: #333;
  font-family: 'Arial, Helvetica, sans-serif';
  margin-bottom: 40px;
}

.sign-up-page-footer {
  margin-top: 10px !important;
}

.sign-up-page-submit-button {
  background-color: #5F9EA0;
}
