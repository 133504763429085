.paper-instructions-container {
  height: auto;
  margin: 10px auto;
  padding-top: 20px;
}

.instruction-text-container {
  display: flex;
  margin-top: 16px;
  padding: 20px;
}

.instruction-text {
  text-align: left; /* Align text to the left */
  border-radius: 4px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  width: 100%; /* Ensure the instruction text container takes up the full width */
  word-wrap: break-word; /* Break long words to the next line */
  overflow-wrap: break-word; /* Additional property to ensure word breaking */
  white-space: normal; /* Allow wrapping of text onto the next line */
  padding : 0 10px;
}


.add-instruction-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  flex-direction: row;
}

.add-instruction-box:hover .MuiIconButton-root,
.add-instruction-box:hover .MuiTypography-root {
  color: #22cfbc;
}

.outlined-button {
  border: 2px solid #46827a;
  back: #46827a;
  padding: 8px 16px;
  border-radius: 20px;
  text-transform: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.outlined-button:hover {
  background-color: rgba(70, 130, 122, 0.1);
}

.filled-button {
  background-color: #46827a;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  text-transform: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.filled-button:hover {
  background-color: #366e65;
}
