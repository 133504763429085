/* Subject score container */
.subject-score-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* District header with circular images */
.district-header img {
  border-radius: 50%;
}

/* Gauge score styling */
.gauge-score {
  font-size: 1.2rem;
  margin-top: 10px;
  font-weight: bold;
}

/* Container for metrics, padding, and background styling */
.metrics-container {
  padding: 20px;
  background-color: #f5f5f5;
  height: 100vh;
  overflow-y: auto;
}

/* Header for cards */
.card-header {
  margin-bottom: 20px;
}

/* Image styling for cards */
.card-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

/* Margin for graphs inside cards */
.card-graph {
  margin-bottom: 20px;
}

/* Flexbox for header elements */
.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Card Title - Large text for titles */
.card-title {
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Dark grey color for titles */
  text-align: center;
  margin-bottom: 10px;
}

/* Card Value - Highlighted large numbers */
.card-value {
  font-size: 24px;
  font-weight: bold;
  color: #5ac8fa; /* Blue color for pass rate */
  text-align: center;
}

/* Smaller text for additional data in cards */
.card-small-text {
  font-size: 12px;
  color: #5f9ea0; /* Green for passed values */
  text-align: center;
}

/* Small text for failed results */
.card-small-text.fail {
  color: pink; /* Red for failed values */
}

/* Pass rate text */
.card-value.pass-rate {
  color: #5f9ea0; /* Blue for pass rate percentage */
}

/* Percentage difference in performance */
.card-percentage-diff {
  font-size: 12px;
  text-align: center;
}

/* Pass percentage styling */
.pass {
  color: #5f9ea0;
}

/* Fail percentage styling */
.fail {
  color: pink;
}
/* Small card size */
.small-card {
  height: 80%; /* Reduced height */
  border-radius: 10px; /* Adjust the border radius */
  padding: 10px; /* Add padding */
}

/* Card Title - Replacing Typography h6 */
.card-title {
  font-size: 14px; /* Smaller font size for title */
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 8px;
}

/* Card Value - Replacing Typography h4 */
.card-value {
  font-size: 18px; /* Smaller font for values */
  font-weight: bold;
  color: #5ac8fa; /* Blue color for pass rate */
  text-align: center;
}

/* Small text for additional data */
.card-small-text {
  font-size: 0.85rem;
  color: #5ac8fa; /* Blue color for pass rate */
  text-align: center;
  margin-top: 5px;
}

/* Percentage difference */
.card-percentage-diff {
  font-size: 12px;
  text-align: center;
}

/* Pass percentage styling */
.pass {
  color: #5f9ea0;
}

/* Fail percentage styling */
.fail {
  color: pink;
}

/* Styling for the chart card */
.chart-card {
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 10px;
}

/* Styling for the chart container to fit properly */
.chart-container {
  width: 100%;
  height: 70%;
  padding: 0 15px; /* Adds padding inside the chart */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for the chart title */
.chart-title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Arial', sans-serif; /* Custom font */
}


/* Styling for the chart container */
.chart-container {
  height: 250px;
  width: 100%;
}

/* Optional: further styling for responsive design */
@media (max-width: 768px) {
  .chart-card {
    margin: 5px 0;
  }
}

.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.tooltip-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.school-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* Styling for the toggle container */
.toggle-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Styling for the toggle button */
.toggle-button {
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: #5ac8fa; /* Adjust color to your theme */
  color: white;
  text-transform: none;
  box-shadow: none;
  transition: background-color 0.3s ease-in-out;
}

.toggle-button:hover {
  background-color: #5ac8fa; /* Slightly darker on hover */
}
