/* Container to center the card */
.onboarding-card-container {
    display: flex;
    justify-content: center;
    height: auto;
    padding: 10px;
    background-color: #32d9be;
}

/* Card styling */
.onboarding-card-educase {
    width: 75vw;
    height: 100vh;
    margin-top: 10px;
    max-width: 1100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    color: #323e48;
    padding: 10px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    text-align: left;
    overflow-y: auto;
    flex-grow: 1;
}

/* Title */
.title-educase {
    font-size: clamp(1.8rem, 4vw, 2.5rem);
    margin-bottom: 20px;
    font-weight: 500;
    text-align: left;
    color: #323e48;
}

/* Subtitle */
.subtitle-educase {
    font-size: clamp(1.2rem, 3vw, 1.2rem);
    margin-bottom: 20px;
    font-weight: normal;
    text-align: left;
}

/* Instruction text */
.instruction-educase {
    font-size: clamp(1rem, 2vw, 1rem);
    margin-bottom: 20px;
    text-align: left;
}

/* Input label styles */
.input-label-educase {
    font-size: clamp(1rem, 2vw, 1.2rem);
    color: #323e48;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

/* Input field styles */
.input-field-educase {
    background-color: #FFFFFF;
}

/* Button styles */
.next-button-educase {
    min-width: 100px;
    align-self: flex-end;
}

.error-text {
    color: red;
    font-size: 12px;
    animation: flicker 1s ease-in-out infinite alternate;
}

@keyframes flicker {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}


/* Responsive design for smaller screens */
@media (max-width: 750px) {
    .onboarding-card-educase {
        padding: 20px;
        height: auto;
        width: 100vw;
        overflow-y: auto;
    }

    .title-educase, .subtitle-educase, .instruction-educase {
        text-align: left;
    }

    .input-label-educase {
        text-align: left;
    }
}

@media (max-width: 600px) {
        .title-educase, .subtitle-educase, .instruction-educase {
        margin-left: 0;
    }
}
