.answer-container {
    padding: 10px;
    margin: 10px 0;
}

.answer-label {
    font-weight: bold;
    margin-bottom: 8px;
}

.list-container {
    padding-left: 30px;
    margin-left: 0;
    margin-bottom: 5px;
    margin-top: 0px;
}

.item-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.nested-question-number {
    margin-right: 5px;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.flex-start-detail {
    display: flex;
    justify-content: flex-start;
    color: #5F9EA0; /* Your main theme color */
    font-size: 12px;
    margin-top: 5px;
}

.nested-question-marks {
    margin-left: auto;
    font-size: 16px;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 640px) and (min-width: 321px) {
    .nested-question-marks,
    .nested-question-number {
        margin-right: 5px;
        font-size: 16px;
        font-family: 'Times New Roman', Times, serif;
    }
}

@media (max-width: 320px) {
    .nested-question-marks,
    .nested-question-number {
        margin-right: 5px;
        font-size: 11px;
        font-family: 'Times New Roman', Times, serif;
    }
}