.class-topic-results-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Navigation Bar */
.navbar {
  background-color: #5F9EA0;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.navbar ul li {
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.navbar ul li.active {
  font-weight: bold;
  color: #e0f7fa; /* Highlight the active topic */
}

.navbar ul li:hover {
  color: #e0f7fa; /* Light blue hover effect */
}

/* Cards Section */
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #333;
}

.card p {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #5F9EA0;
}

/* Custom Background Colors for Each Card */
.average-mark {
  background-color: #e3f2fd; /* Light blue */
}

.pass-rate {
  background-color: #f0f4c3; /* Light green */
}

.fail-rate {
  background-color: #ffccbc; /* Light orange */
}

/* Table Section */
.modern-table {
  width: 100%;
  margin-bottom: 20px;
  overflow-x: auto;
}

.modern-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Ensure all columns respect their defined widths */
.modern-table th,
.modern-table td {
    padding: 12px 15px; /* Consistent padding */
    vertical-align: top;
    text-align: left;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd; /* Add this line for column borders */
    overflow: hidden; /* Prevent overflow */
}

/* Remove the right border from the last column */
.modern-table th:last-child,
.modern-table td:last-child {
    border-right: none;
}


.modern-table th:nth-child(2),
.modern-table td:nth-child(2) {
    width: 60%;
    max-width: 60%;
    min-width: 60%;
    overflow: hidden;
    word-wrap: break-word;
}

.modern-table th:nth-child(1),
.modern-table td:nth-child(1) {
    width: 40%;
    max-width: 40%;
    min-width: 40%;
    overflow: hidden;
    word-wrap: break-word;
}

.modern-table th {
  background-color: #5F9EA0;
  color: white;
  font-weight: bold;
}

.modern-table tr:hover {
  background-color: #f5f5f5;
}

.modern-table td {
  background-color: white;
}

.topic-cell h3 {
    margin: 0 0 10px 0; /* Add some space below the topic name */
    color: #333;
    align-self: flex-start; /* Ensure the topic name is at the top */
}

.topic-cell p {
  margin: 5px 0;
  color: #666;
}

/* Fixed width for Topic column */
.topic-cell {
    width: 250px; /* Adjust width to your desired size */
    max-width: 250px; /* Ensure it doesn't grow beyond this */
    overflow: hidden; /* Prevent content overflow */
    word-wrap: break-word; /* Wrap text properly */
    white-space: normal; /* Allow wrapping */
    display: block; /* Ensure it behaves like a block container */
}

.topic-cell p {
    margin: 5px 0; /* Adds some spacing between paragraphs */
    color: #666;
}

.topic-cell b {
    display: block; /* Makes the bold labels block-level for better spacing */
    margin-bottom: 5px;
}

/* Loading Spinner */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #5f9ea0; /* Color #5f9ea0 */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Apply similar styles to question text */
.question-container,
.sub-question-container {
    max-width: 800px; /* Adjust as needed */
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}

/* Ensure display content wraps properly */
.question-text,
.sub-question-text {
    display: block;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}
.question-number,
.sub-question-number {
    flex-shrink: 0; /* Prevent the number from shrinking */
    min-width: 30px; /* Optional: Reserve space for the number */
    text-align: right; /* Align the number to the right for neatness */
}

.question-row,
.sub-question-row {
    display: flex; /* Use flexbox to arrange elements in a row */
    align-items: flex-start; /* Vertically align the number and text */
    gap: 10px; /* Add space between the number and text */
    margin-bottom: 5px; /* Add spacing between rows */
}

.instructions-container,
.sub-instructions-container {
    margin-top: 5px;
    margin-left: 20px;
}

/* Ensure the text content is displayed next to the question number */
.question-text,
.sub-question-text {
    word-wrap: break-word; /* Ensure long text wraps to the next line */
    overflow: hidden; /* Prevent text from overflowing */
    flex-grow: 1; /* Allow text to take up remaining space */
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}

.card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: #333;
}

.card p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    line-height: 1.4; /* Adjust for readability */
}

.ask-ai-button {
    background-color: white !important;
    border: none !important;
    box-shadow: none !important;
    color: #5F9EA0 !important;
    font-size: 0.75rem;
    padding: 6px 12px;
    margin-left: 12px;
}

.ask-ai-button:hover {
    background-color: #f0f0f0 !important; /* Subtle hover effect */
    box-shadow: none !important;
}
